import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GraphQLModule } from './graphql.module';
import { MainWindowComponent } from './main-window/main-window.component';
import { UIModule } from './ui/ui.module';
import { VideoContainerModule } from './video-container/video-container.module';

@NgModule({
  declarations: [AppComponent, MainWindowComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    GraphQLModule,
    UIModule,
    VideoContainerModule,
    AuthModule.forRoot(environment.auth0),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }, Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
