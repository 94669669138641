import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/auth.service';
import { ConfigService } from '@app/core/config.service';
import { RealtimeDbService } from '@app/core/realtime-db.service';
import {
  InternalUserInfoForMainWindow,
  InternalUserInfoForMainWindow_internalUser,
} from '@app/main-window/__generated__/InternalUserInfoForMainWindow';
import { NavBarUrls } from '@app/ui/nav-bar/nav-bar.component';

export const GET_INTERNAL_USER_INFO_FOR_MAIN_WINDOW_QUERY = gql`
  query InternalUserInfoForMainWindow {
    internalUser {
      id
      displayName
      homeOffice {
        id
      }
      serviceArea {
        serviceAreaGroup {
          serviceAreas {
            id
          }
        }
      }
    }
  }
`;

@Component({
  selector: 'om-main-window',
  templateUrl: './main-window.component.html',
})
export class MainWindowComponent implements OnInit, OnDestroy {
  displayName: string;
  links: NavBarUrls;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private realtimeDbService: RealtimeDbService,
    private apollo: Apollo,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.apollo
      .watchQuery({ query: GET_INTERNAL_USER_INFO_FOR_MAIN_WINDOW_QUERY })
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((response: ApolloQueryResult<InternalUserInfoForMainWindow>) => {
        const user = response.data.internalUser;
        const onelifeUrl = this.configService.environment.onelifeServer;
        const adminUrl = this.configService.environment.adminServer;

        this.displayName = user.displayName;
        this.links = {
          home: `${onelifeUrl}/admin`,
          faxes: `${onelifeUrl}/fax/send_fax`,
          docs: `${onelifeUrl}/admin/inbox?${this.convertServiceAreasToUrlParams(user)}`,
          contacts: `${adminUrl}/contacts`,
          providers: `${adminUrl}/provider-directory`,
          tasks: `${adminUrl}/tasks`,
          virtualVisits: `${onelifeUrl}/admin/video_appointments`,
          appointments: `${onelifeUrl}/admin/offices/${user.homeOffice.id}/appointments`,
          walkInVisits: `${onelifeUrl}/admin/offices/${user.homeOffice.id}/walkin_visits`,
          providerSchedule: `${onelifeUrl}/admin/providers/${user.id}/appointments`,
          officeSchedule: `${onelifeUrl}/admin/offices/${user.homeOffice.id}/schedules`,
        };
      });
  }

  logout() {
    this.realtimeDbService.auth.logout();
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private convertServiceAreasToUrlParams(user: InternalUserInfoForMainWindow_internalUser) {
    return user.serviceArea.serviceAreaGroup.serviceAreas
      .map(serviceArea => `service_area[]=${serviceArea.id}-ServiceArea`)
      .join('&');
  }
}
