import { Component } from '@angular/core';

import { AuthService } from '@app/core/auth/auth.service';

@Component({
  selector: 'om-login',
  templateUrl: './login.component.html',
  standalone: true,
})
export class LoginComponent {
  constructor(private authService: AuthService) {}

  login() {
    this.authService.login();
  }
}
