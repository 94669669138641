import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { from, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ConfigService } from '@app/core/config.service';

export function createApollo(httpLink: HttpLink, configService: ConfigService) {
  const uri = `${configService.environment.apiServer}/api/graphql`;

  return {
    link: from([httpLink.create({ uri })]),
    cache: new InMemoryCache({
      typePolicies: {
        InternalUserPreferences: {
          fields: {
            licensingBodies: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            firebase: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  };
}

@NgModule({
  exports: [],
  imports: [ApolloModule, HttpClientModule, BrowserModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigService],
    },
  ],
})
export class GraphQLModule {}
