import { Injectable } from '@angular/core';
import { AuthService as Auth0AuthService } from '@auth0/auth0-angular';
import { noop, Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { FeatureFlagService } from '../feature-flag.service';
import { LoggerService } from '../logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private auth0AuthService: Auth0AuthService,
    private loggerService: LoggerService,
    private featureFlagService: FeatureFlagService,
  ) {}

  login(): void {
    this.log('Attempting to log in');
    this.auth0AuthService.loginWithRedirect();
  }

  logout(): void {
    this.log('Logging out');
    this.auth0AuthService.logout({ returnTo: `${window.location.origin}/login` });
  }

  initAuthDependentServices$(): Observable<void> {
    return this.auth0AuthService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.auth0AuthService.getAccessTokenSilently();
        } else {
          return observableOf(null);
        }
      }),
      tap(token => {
        if (token) {
          this.loggerService.setSessionToken(token);
          this.featureFlagService.init();
        }
      }),
      take(1),
      map(noop),
    );
  }

  private log(message: string) {
    this.loggerService.log(message, { context: 'Authentication' });
  }
}
