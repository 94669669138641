import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { DevRouteGuardService } from '@app/core/dev-route-guard.service';
import { LoginComponent } from '@app/core/login/login.component';
import { RealtimeDbAuthGuard } from '@app/core/realtime-db-auth-guard.service';
import { MainWindowComponent } from '@app/main-window/main-window.component';
import { VideoContainerComponent } from '@app/video-container/video-container.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'video/:visitId/:sessionId',
        component: VideoContainerComponent,
        canActivate: [RealtimeDbAuthGuard],
      },
      {
        path: '',
        component: MainWindowComponent,
        children: [
          {
            path: 'errors',
            loadChildren: () => import('@app/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
          },
          {
            path: 'dev',
            loadChildren: () => import('@app/dev/dev.module').then(m => m.DevModule),
            canActivate: [DevRouteGuardService, RealtimeDbAuthGuard],
          },
          {
            path: '',
            canActivate: [RealtimeDbAuthGuard],
            loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
