import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/auth/auth.service';

@Component({
  selector: 'om-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.initAuthDependentServices$().subscribe();
  }
}
